//Texte et commentaire suite au resultat des quiz

let commentResult = {
    level1:"Surtout...ne touche à rien!!...fait appel à un pro...",
    level2:"Pour l'instant tu as besoins d'un pro...",
    level3:"Forme toi encore un peu sur ce sujet...ou fait appel à un pro",
    level4:"Tu es sur la bonne voie...!",
    level5:"Si tu as les outils...lance toi",
    level6:"Je n'ai plus grand chose à t'apprendre sur ce sujet",
}

export {commentResult}