//Objet qui contient les url des differents types et tailles d' images pour le composant "Banner"



const urlImgBanner = {
  home: {
    small:"/Asset/images_page_home/light-bulbs-500px.png",
    medium: "/Asset/images_page_home/light-bulbs-1000px.png",
    large: "/Asset/images_page_home/light-bulbs-1500px.png",
  },
};

export { urlImgBanner };
