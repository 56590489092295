//Page "TrucEtAstuce"

//Import des feuilles de style

//Fonction "PageTrucEtAstuce"
function PageTrucEtAstuce() {
  return <div></div>;
}

export { PageTrucEtAstuce };
