let breakPoint ={
   small_Min:575,
   small_Max:576,

   medium_Min: 768, 
   medium_Max: 769, 

   large_Min: 992,
   large_Max: 993, 

   x_large_Min: 1500,
   x_large_Max: 1501,
   
   xx_large_Min: 1800 
};

export {breakPoint}