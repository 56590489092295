//Page "DisjoncteurMagnetoThermique"

//Import des feuilles de style

//Fonction "PageDisjoncteurMagnetoThermique"
function PageDisjoncteurMagnetoThermique() {
  return <div></div>;
}

export { PageDisjoncteurMagnetoThermique };
