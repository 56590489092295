//Page "TableauDeCommunication"

//Import des feuilles de style

//Fonction "PageTableauDeCommunication"
function PageTableauDeCommunication() {
  return <div></div>;
}

export { PageTableauDeCommunication };
