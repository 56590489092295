//Page "TGBT"

//Import des feuilles de style

//Fonction "PageTGBT"
function PageTGBT() {
  return <div></div>;
}

export { PageTGBT };
